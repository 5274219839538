import React from 'react'
import { GetStaticPaths, GetStaticProps } from 'next'
import { getTheatricalRelease } from 'src/services/Theatrical'
import { Durations } from '@/constants/durations'
import { NextPageWithLayout } from '@/layouts'
import { TicketsLayout } from '@/layouts/TicketLayout'
import { getWebClient } from '@/services/ApolloClient'
import {
  getTheatricalTrailerExperiment,
  TheatricalTrailerExperimentType,
} from '@/services/CmsService/TheatricalTrailerExperiment'
import { contentfulFaqLocations } from '@/services/FaqService'
import { getGroupTicketOptionsBySlug } from '@/services/GroupTicketService'
import { getProjectGuildScore } from '@/services/ProjectsService'
import { getTheatricalPromo, TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { TheatricalReleaseObject, TheatricalReleaseRegion } from '@/types/codegen-federation'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { loadTranslations } from '@/utils/translate/translate-server'
import { ShowtimesView } from '@/views/TicketCheckoutViews/ShowtimesView'

interface TicketsPageProps {
  groupTicketOptions: GroupTicketOption[]
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  trailerExperiment: TheatricalTrailerExperimentType
  promo?: TheatricalPromoType
  projectGuildScore: {
    guildScore: number | undefined
  }
}

export const TicketsHypebotPage: NextPageWithLayout<TicketsPageProps> = ({
  groupTicketOptions,
  theatricalRelease,
  theatricalSlug,
  trailerExperiment,
  projectGuildScore,
  promo,
}) => {
  return (
    <TicketsLayout
      shouldShowLanguageModal
      isDarkMode={true}
      shouldRemoveNavOnMobile
      regions={theatricalRelease?.theatricalReleaseRegions as TheatricalReleaseRegion[]}
    >
      <ShowtimesView
        shouldUseDataDog
        promo={promo}
        trailerExperiment={trailerExperiment}
        theatricalRelease={theatricalRelease}
        theatricalSlug={theatricalSlug}
        groupTicketOptions={groupTicketOptions}
        projectGuildScore={projectGuildScore}
      />
    </TicketsLayout>
  )
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ params, preview = false }) =>
  getStaticPropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)
    const theatricalSlug = params?.slug
    const client = getWebClient({ locale })

    if (!theatricalSlug || Array.isArray(theatricalSlug)) {
      return {
        notFound: true,
      }
    }
    const groupTicketOptions = await getGroupTicketOptionsBySlug(theatricalSlug, contentfulFaqLocations.pif, {
      locale,
      preview,
    })

    const theatricalRelease = await getTheatricalRelease({ theatricalSlug, client })
    const projectGuildScore = await getProjectGuildScore(theatricalSlug)
    const trailerExperiment = await getTheatricalTrailerExperiment({ slug: theatricalSlug, locale, preview })
    const promo = await getTheatricalPromo({ slug: theatricalSlug, locale, preview })

    if (!theatricalRelease) {
      return {
        notFound: true,
      }
    }

    try {
      const translations = await loadTranslations(locale, ['common', 'home', 'tickets', 'chatbot', 'account'])
      return {
        props: {
          theatricalSlug,
          promo,
          trailerExperiment,
          groupTicketOptions: groupTicketOptions?.groupTicketOptionCollection?.items ?? [],
          theatricalRelease,
          projectGuildScore: projectGuildScore.guildScore
            ? projectGuildScore
            : { ...projectGuildScore, guildScore: null },
          ...translations,
        },
        revalidate: Durations.TWO_MINUTES_IN_SECONDS,
      }
    } catch (e) {
      return {
        notFound: true,
        revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
      }
    }
  })

TicketsHypebotPage.getLayout = (page) => {
  return page
}
export default TicketsHypebotPage
